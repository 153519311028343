import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
//import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import {
    GET_CompanyDashboard_PayrollVendorReportDetailList, MarkAccountantApproval
    , MarkClientApproval, Vendor1099Mailed, Vendor1099Exported, AccountReconciliationReviewSummery
} from '../../../../appRedux/actions/ReportDetailAction';
import { ReportType, MaskTaxIdentifier, numberWithCommas,GetLastQuarterEndDate,LinkType } from '../../../common/commonServices'
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import HistoryIcon from '@material-ui/icons/History';
import VendorsLogHistory from '../../../common/vendorsHistoryModel'
import Tooltip from '@material-ui/core/Tooltip';

class Report1099Status extends Component {
    constructor(props) {
        super(props);

        let eDate = new Date();
        let sDate = new Date(eDate.getFullYear(), 0, 1);

        if (eDate.getMonth() <= 2) {
            sDate = new Date(sDate.getFullYear() - 1, 0, 1);
            eDate = new Date(eDate.getFullYear() - 1, 11, 31);
        }

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear ; year >= currentYear - 25; year--) {
        if (year >= 2020) {
            years.push(year);
          }
    }
    this.years = years
        this.state = {
            //parantData: this.props.location.state ? this.props.location.state : null,
            MissingData: [],
            companyId: 0,
            sortField: 'name',
            sortFormat: "A",
            PageNo: 1,
            PageSize: 10,
            ColorCode: "#f0ca4c",
            IsCommentModelOpen: false,
            sDate: sDate,
            eDate: eDate,
            loading: false,
            TotalRecords: 0,
            CommentReferenceId: 0,
            IsEmbeddedPage: false,
            SelectedRecords: [],
            SelectALL: false,
            ShowComplete: true,
            failedLog: 0,
            ACRSummary: "No",
            lastReconciledThrough:null,
            ShowHistoryModal:false
        }
    }
    YearChange = (e) => {

        this.setState({ eDate: new Date(e.target.value, 11, 31),sDate: new Date(e.target.value, 0, 1)},() => {
    
          this.GetReportData();
        });
      }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));
        this.setState({
            companyId: user.companyId,
            loading: true
        }, () => {
            this.props.AccountReconciliationReviewSummery(user.companyId);
            this.GetReportData();
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.ReportData !== this.props.ReportData) {
            if (nextProps.ReportData.transactionStatus === 0) {
                var totalrecords = 0;
                let items = nextProps.ReportData.returnObject;
                if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
                    totalrecords = nextProps.ReportData.returnObject[0].totalrows
                    items.map((dt, index) => {
                        items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
                    });
                }
                this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false });
            }
            else {
                toast.error(nextProps.ReportData.resultMsg);
                this.setState({ loading: false });
            }
        }
        if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
            if (nextProps.UpdateDescription.transactionStatus === 0) {
                this.setState({ 
                    // loading: true
                    loading: false
                 }, () => {
                    toast.success(`${nextProps.UpdateDescription.resultMsg}`);
                    this.GetReportData();
                });
            }
            else {
                this.setState({
                    loading: false
                }, () => {
                    toast.error(`${nextProps.UpdateDescription.resultMsg}`);
                });
            }
        }

        if (nextProps.ARCRSummary !== this.props.ARCRSummary) {
            if (nextProps.ARCRSummary.transactionStatus === 0) 
            {
                if(nextProps.ARCRSummary.returnObject.length>0)
                {
                    this.setState({ loading: false,ACRSummary:nextProps.ARCRSummary.returnObject[0].haveAllCheckingAccounts?"Yes":"No",lastReconciledThrough:nextProps.ARCRSummary.returnObject[0].lastReconciledThrough});
                }                
            }
            else
            {
                this.setState({ loading: false},() =>{ toast.error(`${nextProps.ARCRSummary.resultMsg}`)});
            }
            
        }

    }

    ChangePageSize = (size) => {
        this.setState({ 
            loading: true
            // loading: false
            , PageSize: parseInt(size), PageNo: 1 }, () => {
            this.GetReportData();
        });
    }

    ChangePage = (page) => {
        this.setState({ 
            loading: true
            // loading: false
            , PageNo: page, }, () => {
            this.GetReportData();
        });
    }
    CloseHistory = () => {
        this.setState({ ShowHistoryModal: false });
    }
    getHistory=(item) => {
        this.setState({vendorID:item.vendorID,ShowHistoryModal: true})
    
      }

    GetReportData(IsRefresh = false) {
        let state = this.state;
        let body = {
            companyID: state.companyId,
            reportType: ReportType.Vendor1099StatusReport,
            fromDate: moment(state.sDate).format("MM/DD/YYYY"),
            toDate: moment(state.eDate).format("MM/DD/YYYY"),
            sortField: state.sortField,
            sortFormat: state.sortFormat,
            pageNumber: state.PageNo,
            pageSize: state.PageSize,
            needUpdatQBORecords: IsRefresh
        };

        this.props.GET_CompanyDashboard_PayrollVendorReportDetailList(body);
    }


    SelectALL = (e) => {
        if (this.state.MissingData.length > 0) {
            if (e.target.checked) {
                let all = [];
                let data = this.state.MissingData;
                for (let i = 0; i < data.length; i++) {
                    all.push(data[i].listID);
                }
                this.setState({ SelectALL: true, SelectedRecords: all });
            }
            else {
                this.setState({ SelectALL: false, SelectedRecords: [] });
            }
        }
    }


    onSortChange = (fieldName) => {
        if (this.state.sortField == fieldName) {
            let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
            this.setState({ sortFormat: formate,
                 loading: true
                // loading: false
                 }, () => {
                this.GetReportData();
            });
        } else {
            this.setState({ sortField: fieldName, sortFormat: 'A', 
            loading: true
            // loading: false
         }, () => {
                this.GetReportData();
            });
        }
    }
    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true }, () => {
                this.GetReportData(true);
            });
        }
    }

    OnApplyApproved = () => {
        let selectedData = this.state.SelectedRecords;
        if (selectedData == null || selectedData.length === 0) {
            toast.error("Please select atleast 1 record for processing request");
            return false;
        }
        let body = {
            "ids": selectedData,
            "reportType": ReportType.IssueHousingAllowanceMemo,
            "companyID": this.state.companyId,
        }

        // this.setState({ loading: true }, () => {
        //     this.props.IncludeHAMemo(body);
        // });
    }

    UpdateVendorAction = (e, type, item) => {

        let errMsg = "Accountant and Client approval is required before Export and Mailed";
        let isValid = true;
        if (type != null) {
            let body = {
                "companyID": this.state.companyId,
                "ids": [parseInt(item.listID)],
                "reportType": ReportType.Vendor1099PendingAccountantApproval,
                "IsRemove": e.target.checked ? false : true
            }
            if (type === "Accountant") {
                body.reportType = ReportType.Vendor1099PendingAccountantApproval;
                this.setState({ 
                    // loading: true 
                    loading: false
                }, () => {
                    this.props.MarkAccountantApproval(body);
                })
            }
            else if (type === "Client") {
                body.reportType = ReportType.Vendor1099PendingClientApproval;
                this.setState({
                    //  loading: true
                    loading: false
                     }, () => {
                    this.props.MarkClientApproval(body);
                })
            }
            else if (type === "Exported") {
                body.reportType = ReportType.Vendor1099StatusReport;
                if (body.IsRemove) {
                    if (item.alreadyExported) {
                        this.setState({ 
                            // loading: true
                            loading: false
                         }, () => {
                            this.props.Vendor1099Exported(body);
                        });
                    }
                }
                else {
                    if (item.vendor1099ApprovedByAccountant) {
                        if (item.vendor1099ApprovedByClient) {
                            if (item.readyForExport) {
                                this.setState({ 
                                    // loading: true
                                    loading: false
                                 }, () => {
                                    this.props.Vendor1099Exported(body);
                                });
                            }
                            else {
                                toast.error("Vendor details missing or amount is not as expected or must have anyone of from this vendor type 'Rent','Royalties','Other','Non-Employee Compensation'");;
                                isValid = false;
                            }
                        }
                        else {
                            toast.error(errMsg);
                            isValid = false;
                        }

                    }
                    else {
                        toast.error(errMsg);
                        isValid = false;
                    }
                }
            }
            else if (type === "Mailed") {
                body.reportType = ReportType.Confirm1099WereMailed;
                if (body.IsRemove) {
                    if (item.isMailed) {
                        this.setState({ 
                            // loading: true
                            loading: false
                         }, () => {
                            this.props.Vendor1099Mailed(body);
                        });
                    }
                }
                else {
                    if (item.vendor1099ApprovedByAccountant && item.vendor1099ApprovedByClient && item.alreadyExported) {
                        this.setState({ 
                            // loading: true
                            loading: false
                         }, () => {
                            this.props.Vendor1099Mailed(body);
                        });
                    }
                    else {
                        toast.error(errMsg);
                        isValid = false;
                    }
                }
            }

            if (!isValid) {
                let data = this.state.MissingData;
                data.forEach(element => {
                    if (element.listID == item.listID) {
                        element.alreadyExported = item.alreadyExported;
                        element.alreadyMailed = item.alreadyMailed;
                    }
                });
                this.setState({ MissingData: data, failedLog: this.state.failedLog + 1 });
            }
        }
    }

    onCheckboxClick = (e, type, item) => {
        e.stopPropagation();
        let existInArray = false;
        let data = this.state.SelectedRecords;
        if (data != null && data.length > 0 && data.indexOf(item.listID) > -1) {
            existInArray = true;
        }

        if (e.target.checked) {
            if (!existInArray) {
                data.push(item.listID);
            }
            this.UpdateVendorAction(e, type, item);
        }
        else {
            if (existInArray) {
                data.splice(data.indexOf(item.listID), 1);
            }
            this.UpdateVendorAction(e, type, item);
        }
        let all = (data.length == this.state.MissingData.length) ? true : false;
        this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
    }


    render() {
        return (
            <div className="dashboardPage">
                <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
                    <h2 className="mb-3 mb-sm-0">{JSON.parse(localStorage.getItem('userData')) != null ? JSON.parse(localStorage.getItem('userData')).companyName : ""} | {LinkType.StatusReport1099}</h2>
                </div>
                {this.state.loading ? <CircularProgress></CircularProgress>
                    :
                    <div className="padding-box app-wrapper">
                        {this.state.ShowHistoryModal ?  <VendorsLogHistory  Module="Vendors History"
                    CompanyId={this.state.companyId}
                    VendorId={this.state.vendorID}
                    CloseHistory={this.CloseHistory}/>: '' }
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="float-left arc_summary">
                                        <span>Have all checking accounts been reconciled through {this.state.lastReconciledThrough}?: {this.state.ACRSummary}  </span>
                                        </div>
                                        <div className="float-right">
                                        <span>Select Year:</span>
                                         <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{marginRight:"10px",height:'40px'}}>
                                            {/* <option value=""></option> */}
                                            {this.years.map((option, index) => (
                                               <option key={index} value={option}>
                                                 {option}
                                               </option>
                                             ))}
                                        </select>
                                            {/* <Button variant="raised" className=" jr-btn g-teal  text-white refresh-btn graycolor" onClick={() => this.OnApplyApproved()}>Exported </Button> */}
                                            <Button variant="raised" style={{ backgroundColor: this.state.ColorCode }} className="jr-btn text-white refresh-btn" onClick={() => this.onRefreshDataClick()}  ><i className="zmdi zmdi-refresh "></i> Refresh Data</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive-material">
                            <table className="table tablebox account-reconcilliation">
                                <thead>
                                    <tr style={{ backgroundColor: this.state.ColorCode }}>
                                        <th className={`td-width ${this.state.sortField == 'name' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('name')}><p className="table-th-title">Display Name</p> </th>
                                        {/* <th className={`td-width  ${this.state.sortField === 'familyName' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('familyName')}><p className="table-th-title"> Last Name</p></th> */}
                                        <th className={`td-width ${this.state.sortField == 'companyName' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('companyName')}><p className="table-th-title">Company Name</p> </th>
                                        <th className={`td-width  ${this.state.sortField === 'address' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('address')}><p className="table-th-title"> Address</p></th>
                                        <th className={`td-width  ${this.state.sortField === 'taxIdentifier' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('taxIdentifier')}><p className="table-th-title"> Tax Identifier</p></th>
                                        <th className={`td-width  ${this.state.sortField === 'vendorType' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('vendorType')}><p className="table-th-title"> Vendor Type</p></th>
                                        <th className={`td-width text-center ${this.state.sortField === 'totalPayment' ? this.state.sortFormat == 'A'
                                            ? "asending" : "desending" : ''}`} onClick={() => this.onSortChange('totalPayment')}><p className="table-th-title"> Total Payment</p></th>
                                        <th className="td-width-90 text-center">
                                            Approved by Accountant
                                        </th>
                                        <th className="td-width-90 text-center">
                                            Approved by Client
                                        </th>
                                        <th className="td-width-90 text-center">
                                            Exported
                                        </th>
                                        <th className="td-width-90 text-center">
                                            Mailed
                                        </th>
                                        <th className="td-width-90 text-center">
                                        W-9/1099 Reporting Issues
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.MissingData != null && this.state.MissingData.length > 0 ?
                                        <React.Fragment key={this.state.failedLog}>
                                            {this.state.MissingData.map((item, i) => (
                                                <tr>
                                                    <td>{item.displayName}</td>
                                                    {/* <td>{item.familyName}</td> */}
                                                    <td>{item.companyName}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.taxIdentifier}</td>
                                                    <td>{item.vendorType}</td>
                                                    <td className="text-center">{numberWithCommas(item.totalPayment)}</td>
                                                    <td >
                                                        <label className="container101">
                                                            {item.vendor1099ApprovedByAccountant ?
                                                                <input className="second-check-box" checked="checked" onChange={(e) => this.onCheckboxClick(e, "Accountant", item)} type="checkbox" />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onChange={(e) => this.onCheckboxClick(e, "Accountant", item)} />
                                                            }
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="container101">
                                                            {item.vendor1099ApprovedByClient ?
                                                                <input className="second-check-box" checked="checked" onChange={(e) => this.onCheckboxClick(e, "Client", item)} type="checkbox" />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onChange={(e) => this.onCheckboxClick(e, "Client", item)} />
                                                            }
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="container101">
                                                            {item.alreadyExported ?
                                                                <input className="second-check-box" checked="checked" onChange={(e) => this.onCheckboxClick(e, "Exported", item)} type="checkbox" />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onChange={(e) => this.onCheckboxClick(e, "Exported", item)} />
                                                            }
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="container101">
                                                            {item.alreadyMailed ?
                                                                <input className="second-check-box" checked="checked" onChange={(e) => this.onCheckboxClick(e, "Mailed", item)} type="checkbox" />
                                                                :
                                                                <input className="second-check-box" type="checkbox" onChange={(e) => this.onCheckboxClick(e, "Mailed", item)} />
                                                            }
                                                            <span className="checkmark101 green_CheckMark"></span>
                                                        </label>
                                                    </td>
                                                    <td><label className="container101" style={{marginTop:30}}>
                                                    <Tooltip title="W-9/1099 Reporting Issues history"><HistoryIcon onClick={(e)=>{this.getHistory(item)}}/></Tooltip> </label></td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                        :
                                        <tr>
                                            <td className="text-center" colSpan="10">
                                            No issues here! Keep up the good work!
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <PaginationComponent
                            key={this.state.MissingData.length}
                            selectedPageNo={this.state.PageNo}
                            selectedSize={this.state.PageSize}
                            TotalRecords={this.state.TotalRecords}
                            ChangePageSize={this.ChangePageSize}
                            ChangePage={this.ChangePage}
                            CurrentRecords={this.state.MissingData.length}
                        >
                        </PaginationComponent>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ReportData: state.ReportData.ReportData,
        UpdateDescription: state.ReportData.UpdateDescription,
        ARCRSummary: state.ReportData.ARCRSummary
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard_PayrollVendorReportDetailList,
        MarkAccountantApproval,
        MarkClientApproval,
        Vendor1099Mailed,
        Vendor1099Exported,
        AccountReconciliationReviewSummery
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Report1099Status);