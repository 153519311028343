import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
//import CommonComments from './../../../common/Comments';
import PaginationComponent from './../../../common/Pagination';
import { GET_CompanyDashboard_PayrollVendorReportDetailList, Vendor1099Exported, AccountReconciliationReviewSummery, UpdateVendorName } from '../../../../appRedux/actions/ReportDetailAction';
import { getCompnay } from '../../../../appRedux/actions/companiesAction';
import { ReportType, MaskTaxIdentifier, numberWithCommas, LinkType } from '../../../common/commonServices';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import CircularProgress from './../../../../components/CircularProgress';
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
const FontTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 12
  },
}))(Tooltip);

class Export1099NEC extends Component {

  constructor(props) {
    super(props);
    let eDate = new Date();
    let sDate = new Date(eDate.getFullYear(), 0, 1);

    if (eDate.getMonth() <= 2) {
      sDate = new Date(sDate.getFullYear() - 1, 0, 1);
      eDate = new Date(eDate.getFullYear() - 1, 11, 31);
    }

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 25; year--) {
      if (year >= 2020) {
        years.push(year);
      }
    }
    this.years = years
    this.state = {
      parantData: this.props.location.state ? this.props.location.state : null,
      MissingData: [],
      companyId: 0,
      sortField: 'name',
      sortFormat: "A",
      PageNo: 1,
      PageSize: 10,
      ColorCode: "#f0ca4c",
      IsCommentModelOpen: false,
      sDate: sDate,
      eDate: eDate,
      loading: false,
      TotalRecords: 0,
      CommentReferenceId: 0,
      IsEmbeddedPage: false,
      SelectedRecords: [],
      SelectALL: false,
      ShowComplete: true,
      FileName: "myfile.csv",
      headers: [
        { label: "Recipient reference number", key: "recipientRefNum" },
        { label: "Recipient type of TIN (1=EIN 2=SSN 3=ITIN 4=ATIN 5=TIN not provided 10=others)*", key: "recipientType" },
        { label: "Recipient TIN*", key: "recipientTIN" },
        { label: "Recipient name (if EIN)*", key: "recipientName" },
        { label: "Recipient first name (if individual TIN)*", key: "recipientFirstName" },
        { label: "Recipient middle initial (if individual TIN)", key: "recipientMiddleName" },
        { label: "Recipient last name (if individual TIN)*", key: "recipientLastName" },
        { label: "Recipient suffix (if individual TIN) (1=Jr 2=Sr 3=I 4=II 5=III 6=IV 7=V 8=VI 9=VII)", key: "recipientSuffix" },
        { label: "Recipient DBA/trade name", key: "recipientDBAOrTradeName" },
        { label: "Recipient country", key: "recipientCountry" },
        { label: "Recipient address line 1", key: "recipientAddressLine1" },
        { label: "Recipient address line 2", key: "recipientAddressLine2" },
        { label: "Recipient city/town", key: "recipientCity" },
        { label: "Recipient state/province/territory", key: "recipientState" },
        { label: "Recipient ZIP code/postal code", key: "recipientPostalCode" },
        { label: "Recipient email address", key: "recipientEmail" },
        { label: "Recipient phone number", key: "recipientPhone" },
        { label: "Account number", key: "recipientAccNum" },
        { label: "Second TIN not. (Yes/No or 1/0 or True/False or x=Yes y=No)", key: "recipientSecondTIN" },
        { label: "Box 1 Nonemployee compensation", key: "box1" },
        { label: "Box 2 Payer made direct sales totaling $5,000 or more of consumer products to recipient for resale (Yes/No or 1/0 or True/False or x=Yes y=No)", key: "box2" },
        { label: "Box 4 Federal income tax withheld", key: "box4" },
        { label: "Box 5a State tax withheld", key: "box5a" },
        { label: "Box 6a State", key: "box6aState" },
        { label: "Box 6a Payer state no.", key: "box6aPayer" },
        { label: "Box 7a State income", key: "box7a" },
        { label: "Box 5b Second state tax withheld", key: "box5b" },
        { label: "Box 6b Second state", key: "box6bState" },
        { label: "Box 6b Second payer state no", key: "box6bPayer" },
        { label: "Box 7b Second State income", key: "box7b" },
      ],
      csvData: [],
      ShowGuidlines: false,
      ACRSummary: "No",
      lastReconciledThrough: null
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('userData'));
    this.setState({
      companyId: user.companyId,
      loading: true
    }, () => {
      this.props.getCompnay(user.companyId);
      this.props.AccountReconciliationReviewSummery(user.companyId);
      this.GetReportData();
    });
  }

  YearChange = (e) => {

    this.setState({ eDate: new Date(e.target.value, 11, 31), sDate: new Date(e.target.value, 0, 1) }, () => {

      this.GetReportData();
    });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.ReportData !== this.props.ReportData) {
      if (nextProps.ReportData.transactionStatus === 0) {
        var totalrecords = 0;
        let items = nextProps.ReportData.returnObject;
        if (nextProps.ReportData.returnObject != null && nextProps.ReportData.returnObject.length > 0) {
          totalrecords = nextProps.ReportData.returnObject[0].totalrows
          items.map((dt, index) => {
            items[index].MaskTax = MaskTaxIdentifier(dt.taxIdentifier);
          });
        }
        if (this.state.csvData.length > 0) {
          document.getElementById("csvlink").click();
        }
        this.setState({ MissingData: items, TotalRecords: totalrecords, PageSize: this.state.PageSize, PageNo: this.state.PageNo, loading: false, SelectedRecords: [], SelectALL: false, csvData: [] });
      }
      else {
        toast.error(nextProps.ReportData.resultMsg);
        this.setState({ loading: false });
      }
    }

    if (nextProps.UpdateDescription !== this.props.UpdateDescription) {
      if (nextProps.UpdateDescription.transactionStatus === 0) {
        toast.success(`Data Export Successfully`);
        this.DownloadCSV();

      }
      else {
        this.setState({
          loading: false, SelectedRecords: [], SelectALL: false
        }, () => {
          toast.error(`${nextProps.UpdateDescription.resultMsg}`);
        });
      }
    }

    if(nextProps.UpdateVendor !== this.props.UpdateVendor) {
      if(nextProps.UpdateVendor.transactionStatus === 0) {
        toast.success(`Data Updated Successfully`);
        this.GetReportData();
        this.setState({ editIndex: null, editData: null, loading: false });
      }
      else {
        this.setState({
          loading: false, SelectedRecords: [], SelectALL: false
        }, () => {
          toast.error(`${nextProps.UpdateVendor.resultMsg}`);
          this.setState({ loading: false });
        });
      }
    }

    if (nextProps.company !== this.props.company) {
      console.log("🚀 ~ Export1099NEC ~ componentWillReceiveProps ~ nextProps.company:", nextProps.company)
      var startDate = this.state.sDate;
      let filename = nextProps.company.name + "_" + startDate.getFullYear() + "-NEC_" + moment(new Date()).format("MM-DD-YYYY") + ".csv";
      this.setState({ loading: false, CompanyInfo: nextProps.company, FileName: filename });
    }

    if (nextProps.ARCRSummary !== this.props.ARCRSummary) {
      if (nextProps.ARCRSummary.transactionStatus === 0) {
        if (nextProps.ARCRSummary.returnObject.length > 0) {
          this.setState({ loading: false, ACRSummary: nextProps.ARCRSummary.returnObject[0].haveAllCheckingAccounts ? "Yes" : "No", lastReconciledThrough: nextProps.ARCRSummary.returnObject[0].lastReconciledThrough });
        }
      }
      else {
        this.setState({ loading: false }, () => { toast.error(`${nextProps.ARCRSummary.resultMsg}`) });
      }

    }
  }

  DownloadCSV = () => {
    var filedata = [];
    var reportData = this.state.MissingData;
    var selectedData = this.state.SelectedRecords;
    for (var i = 0; i < reportData.length; i++) {
      if (selectedData.indexOf(reportData[i].listID) > -1) {
        const firstName = reportData[i].name || "";
        const tin = reportData[i].taxId || "";

        const recipientType = tin.endsWith("6789")
          ? 5
          : firstName.trim() === ""
            ? 1
            : 2;
        filedata.push({
          recipientType: recipientType,
          recipientTIN: reportData[i].taxId,
          recipientName: recipientType === 1 ? reportData[i].companyName : "",
          recipientFirstName: recipientType === 2 ? reportData[i].name : "",
          recipientMiddleName: recipientType === 2 && reportData[i].middleName ? reportData[i].middleName.charAt(0) : "",
          recipientLastName: recipientType === 2 ? reportData[i].familyName : "",
          recipientSuffix: recipientType === 2 ? reportData[i].suffix : "",
          recipientDBAOrTradeName: recipientType === 2 ? reportData[i].companyName : "",
          recipientCountry: "United States",
          recipientAddressLine1: reportData[i].bill1,
          recipientAddressLine2: reportData[i].bill2,
          recipientCity: reportData[i].bill3,
          recipientState: reportData[i].bill4,
          recipientPostalCode: reportData[i].bill5,
          box1: reportData[i].totalPayment_NonEmployee
        });
      }
    }
    this.setState({
      loading: false,
      csvData: filedata
    }, () => {
      this.GetReportData();
    });
  }

  UpdateData = (e, i, item, field) => {
    var data = this.state.editData;
    if (data == null) {
      data = {
        name: item.name,
        familyName: item.familyName,
        companyName: item.companyName,
        address: item.address,
        taxIdentifier: item.taxIdentifier
      };
    }
    if (field === "name") {
      data.name = e.target.value;
    }
    else if (field === "familyName") {
      data.familyName = e.target.value;
    }
    else if (field === "companyName") {
      data.companyName = e.target.value;
    }
    else if (field === "address") {
      data.address = e.target.value;
    }
    else if (field === "taxIdentifier") {
      data.taxIdentifier = e.target.value;
    }

    this.setState({ editData: data });
  }

  ChangePageSize = (size) => {
    this.setState({ loading: true, PageSize: parseInt(size), PageNo: 1 }, () => {
      this.GetReportData();
    });
  }

  ChangePage = (page) => {
    this.setState({ loading: true, PageNo: page, }, () => {
      this.GetReportData();
    });
  }

  GetReportData(IsRefresh = false) {
    let state = this.state;
    let body = {
      companyID: state.companyId,
      reportType: ReportType.Vendor1099NECReadyToExport,
      fromDate: moment(state.sDate).format("MM/DD/YYYY"),
      toDate: moment(state.eDate).format("MM/DD/YYYY"),
      sortField: state.sortField,
      sortFormat: state.sortFormat,
      pageNumber: state.PageNo,
      pageSize: state.PageSize,
      needUpdatQBORecords: IsRefresh,
    };

    this.props.GET_CompanyDashboard_PayrollVendorReportDetailList(body);
  }


  SelectALL = (e) => {
    if (this.state.MissingData.length > 0) {
      if (e.target.checked) {
        let all = [];
        let data = this.state.MissingData;
        for (let i = 0; i < data.length; i++) {
          all.push(data[i].listID);
        }
        this.setState({ SelectALL: true, SelectedRecords: all });
      }
      else {
        this.setState({ SelectALL: false, SelectedRecords: [] });
      }
    }
  }

  onRowSave = (index, data) => {
    let editedData = this.state.editData;
    if (editedData.companyName == null || editedData.companyName.trim() == "") {
      if (editedData.name == null || editedData.name.trim() == "") {
        toast.error(`Please enter first name`);
        return false;
      }

      if (editedData.familyName == null || editedData.familyName.trim() == "") {
        toast.error(`Please enter last name`);
        return false;
      }
    }

    let body =
    {
      "companyID": this.state.companyId,
      "vendorID": data.vendorID,
      "listID": data.listID,
      "firstName": editedData.name,
      "lastName": editedData.familyName,
      "companyName": editedData.companyName,
      "address": editedData.address,
      "taxIdentifier": editedData.taxIdentifier,
      "isNEC": true
    }
    this.setState({
      loading: true
    }, () => {
      this.props.UpdateVendorName(body);
    });
  }

  onRowEdit = (j, rowData) => {
    var originaldata = {
      name: rowData.name,
      familyName: rowData.familyName,
      companyName: rowData.companyName,
      address: rowData.address,
      taxIdentifier: rowData.taxIdentifier
    };
    this.setState({ editData: originaldata, editIndex: j });
  };

  onRowCancel = (rowIndex, rowData) => {
    this.setState({ editData: null, editIndex: -1 });
  }
  onSortChange = (fieldName) => {
    if (this.state.sortField == fieldName) {
      let formate = this.state.sortFormat == 'A' ? 'D' : 'A';
      this.setState({ sortFormat: formate, loading: true }, () => {
        this.GetReportData();
      });
    } else {
      this.setState({ sortField: fieldName, sortFormat: 'A', loading: true }, () => {
        this.GetReportData();
      });
    }
  }
  onRefreshDataClick = () => {
    if (!this.state.loading) {
      this.setState({ loading: true }, () => {
        this.GetReportData(true);
      });
    }
  }

  OnExport = () => {
    let selectedData = this.state.SelectedRecords;
    if (selectedData == null || selectedData.length === 0) {
      toast.error("Please select atleast 1 record for processing request");
      return false;
    }
    let body =
    {
      "ids": selectedData,
      "reportType": ReportType.Vendor1099NECReadyToExport,
      "companyID": this.state.companyId
    }

    this.setState({ loading: true }, () => {
      this.props.Vendor1099Exported(body);
    });
  }

  onCheckboxClick = (e, item) => {
    e.stopPropagation();
    let existInArray = false;
    let data = this.state.SelectedRecords;
    if (data != null && data.length > 0 && data.indexOf(item.listID) > -1) {
      existInArray = true;
    }

    if (e.target.checked) {
      if (!existInArray) {
        data.push(item.listID);
      }
    }
    else {
      if (existInArray) {
        data.splice(data.indexOf(item.listID), 1);
      }
    }
    let all = (data.length == this.state.MissingData.length) ? true : false;
    this.setState({ SelectedRecords: data, SelectALL: all, editIndex: -1 });
  }

  CloseInstruction = () => {
    this.setState({ ShowGuidlines: false })
  }

  ShowWarning = () => {
    this.setState({ ShowGuidlines: true })
  }

  render() {
    let selectedItem = this.state.SelectedRecords;
    return (
      <div className="dashboardPage">
        <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="mb-3 mb-sm-0">
            {JSON.parse(localStorage.getItem("userData")) != null
              ? JSON.parse(localStorage.getItem("userData")).companyName
              : ""}{" "}
            | {LinkType.NEC1099Export}
          </h2>
        </div>
        <div style={{ display: "none" }}>
          <CSVLink
            headers={this.state.headers}
            data={this.state.csvData}
            filename={this.state.FileName}
            id="csvlink"
          >
            Download me
          </CSVLink>
        </div>

        {this.state.ShowGuidlines && (
          <Dialog
            className="comment-Top-align"
            maxWidth="sm"
            fullWidth={true}
            open={true}
          >
            <React.Fragment>
              <div className="Comments modal-header">
                <span className="log-history-title float-left">
                  WARNING{" "}
                </span>
                <Button
                  className="jr-btn close-btn float-right"
                  onClick={this.CloseInstruction}
                >
                  <ClearIcon />
                </Button>
              </div>
              <DialogContent style={{ margin: "8px" }}>
                <p>
                  The 1099 amount for this vendor has changed. However, a
                  1099 was exported and/or mailed for this vendor. Check the
                  tax software to determine if the 1099 was filed
                  electronically and discuss this matter with the CPA.
                </p>
                <p>
                  <ol>
                    <li>
                      If the 1099 has been mailed to the vendor, contact the
                      client. The client must notify the vendor that they
                      will receive a corrected 1099. Add a ""Corrected""
                      watermark at the top of the 1099 before mailing to the
                      vendor.{" "}
                    </li>
                    <li>
                      If the 1099 has not been filed electronically, make
                      sure the tax software reflects the updated amount.{" "}
                    </li>
                    <li>
                      If the 1099 has been filed electronically, use the
                      1099 correction procedures in the tax software to
                      prepare and e-file a corrected 1099.{" "}
                    </li>
                  </ol>
                </p>
              </DialogContent>
            </React.Fragment>
          </Dialog>
        )}
        {this.state.loading ? (
          <CircularProgress></CircularProgress>
        ) : (
          <div className="padding-box app-wrapper">
            <div className="row">
              <div className="col-md-12">
                {/* {JSON.stringify(this.state.CompanyInfo)} */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="float-left arc_summary">
                      {this.state.CompanyInfo ?
                        <Grid container spacing={24} style={{ paddingBottom: 13, columnGap: 40 }}>
                          <Grid item>
                            <h3>
                              <b>Company Legal Name : </b> {this.state.CompanyInfo.name ? this.state.CompanyInfo.name : ''}
                            </h3>
                            <h3>
                              <b>Legal Address : </b> {this.state.CompanyInfo.address ? this.state.CompanyInfo.address : ""}
                            </h3>
                          </Grid>
                          <Grid item>
                            <h3>
                              <b>City : </b> {this.state.CompanyInfo.city ? this.state.CompanyInfo.city : ``}
                            </h3>
                            <h3>
                              <b>State : </b> {this.state.CompanyInfo.state ? this.state.CompanyInfo.state : ``}
                            </h3>
                          </Grid>
                          <Grid item>
                            <h3>
                              <b>Zip : </b> {this.state.CompanyInfo.postalCode ? this.state.CompanyInfo.postalCode : ``}
                            </h3>
                            <h3>
                              <b>EIN Number : </b> {this.state.CompanyInfo.eiNnumber ? this.state.CompanyInfo.eiNnumber : ``}
                            </h3>

                          </Grid>
                        </Grid> : ``}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="float-left arc_summary">

                      <span>
                        Have all checking accounts been reconciled through{" "}
                        {this.state.lastReconciledThrough}?:{" "}
                        {this.state.ACRSummary}{" "}
                      </span>
                    </div>
                    <div className="float-right">
                      <span>Select Year:</span>
                      <select className="select-box arrow-box" name="year" onChange={(e) => this.YearChange(e)} value={this.state.eDate.getFullYear()} style={{ marginRight: "10px", height: '40px' }}>
                        {this.years.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <Button
                        variant="raised"
                        className=" jr-btn g-teal  text-white refresh-btn graycolor"
                        onClick={() => this.OnExport()}
                      >
                        {" "}
                        Export
                      </Button>
                      <Button
                        variant="raised"
                        style={{ backgroundColor: this.state.ColorCode }}
                        className="jr-btn text-white refresh-btn"
                        onClick={() => this.onRefreshDataClick()}
                      >
                        <i className="zmdi zmdi-refresh "></i> Refresh Data
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive-material">
              <table className="table tablebox account-reconcilliation">
                <thead>
                  <tr style={{ backgroundColor: this.state.ColorCode }}>
                    <th
                      className={`td-width ${this.state.sortField == "name"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("name")}
                    >
                      <p className="table-th-title">First Name</p>{" "}
                    </th>
                    <th
                      className={`td-width  ${this.state.sortField === "familyName"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("familyName")}
                    >
                      <p className="table-th-title"> Last Name</p>
                    </th>
                    <th
                      className={`td-width ${this.state.sortField == "companyName"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("companyName")}
                    >
                      <p className="table-th-title">Company Name</p>{" "}
                    </th>
                    <th
                      className={`td-width  ${this.state.sortField === "address"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("address")}
                    >
                      <p className="table-th-title"> Address</p>
                    </th>
                    <th
                      className={`td-width  ${this.state.sortField === "taxIdentifier"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("taxIdentifier")}
                    >
                      <p className="table-th-title"> Tax Identifier</p>
                    </th>
                    <th
                      className={`td-width  ${this.state.sortField === "vendorType"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("vendorType")}
                    >
                      <p className="table-th-title"> Vendor Type</p>
                    </th>
                    <th
                      className={`td-width text-center ${this.state.sortField === "totalPayment"
                        ? this.state.sortFormat == "A"
                          ? "asending"
                          : "desending"
                        : ""
                        }`}
                      onClick={() => this.onSortChange("totalPayment")}
                    >
                      <p className="table-th-title"> Total Payment</p>
                    </th>
                    <th className="td-width-120 text-center">
                      <label className="container101 header-check">
                        {this.state.SelectALL ? (
                          <input
                            className="second-check-box"
                            checked="checked"
                            type="checkbox"
                            onClick={(e) => this.SelectALL(e)}
                          />
                        ) : (
                          <input
                            className="second-check-box"
                            type="checkbox"
                            onClick={(e) => this.SelectALL(e)}
                          />
                        )}
                        <span className="checkmark101 yellowMark white_border"></span>
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.MissingData != null &&
                    this.state.MissingData.length > 0 ? (
                    <React.Fragment>
                      {this.state.MissingData.map((item, i) => (
                        <React.Fragment key={i}>
                          {this.state.editIndex !== i && <tr onClick={(e) => this.onRowEdit(i, item)}>
                            <td>{item.name}</td>
                            <td>{item.familyName}</td>
                            <td>{item.companyName}</td>
                            <td>{item.address}</td>
                            <td>{item.taxIdentifier}</td>
                            <td>{item.vendorType}</td>
                            <td className="text-center">
                              {numberWithCommas(item.totalPayment)}
                              {item.alreadyExported && (
                                <img
                                  className="amt_updated"
                                  onClick={() => this.ShowWarning()}
                                  src="/vendors/Caution.png"
                                  alt="caution"
                                />
                              )}
                            </td>
                            <td onClick={(e) => e.stopPropagation()}>
                              <label className="container101">
                                {this.state.SelectALL ||
                                  (selectedItem.length > 0 &&
                                    selectedItem.indexOf(item.id) > -1) ? (
                                  <input
                                    className="second-check-box"
                                    checked="checked"
                                    type="checkbox"
                                    onClick={(e) =>
                                      this.onCheckboxClick(e, item)
                                    }
                                  />
                                ) : (
                                  <input
                                    className="second-check-box"
                                    type="checkbox"
                                    onClick={(e) =>
                                      this.onCheckboxClick(e, item)
                                    }
                                  />
                                )}
                                <span className="checkmark101 yellow_CheckMark"></span>
                              </label>
                            </td>
                          </tr>
                          }
                          {this.state.editIndex === i && <tr>
                            <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.name : ''} onChange={(e) => this.UpdateData(e, i, item, "name")} placeholder='first name' /> </td>
                            <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.familyName : ''} onChange={(e) => this.UpdateData(e, i, item, "familyName")} placeholder='last name' /> </td>
                            <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.companyName : ''} onChange={(e) => this.UpdateData(e, i, item, "companyName")} placeholder='company' /> </td>
                            <td>  {item.address} </td>
                            <td>  <input type="text" className="form-control" value={this.state.editData != null ? this.state.editData.taxIdentifier : ''} onChange={(e) => this.UpdateData(e, i, item, "taxIdentifier")} placeholder='taxIdentifier' /> </td>
                            <td>  {item.vendorType} </td>
                            <td>  {item.totalPayment} </td>
                            <td colSpan="3" className="text-center">
                              <div className="col-md-12">
                                <Button className="jr-btn jr-btn-sm bg-teal text-white" onClick={() => this.onRowSave(i, item)}>SAVE</Button>
                                <Button className="jr-btn jr-btn-sm bg-danger text-white" onClick={() => this.onRowCancel(i, item)}>CANCEL</Button>
                              </div>
                            </td>
                          </tr>
                          }
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="8">
                        No issues here! Keep up the good work!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <PaginationComponent
              key={this.state.MissingData.length}
              selectedPageNo={this.state.PageNo}
              selectedSize={this.state.PageSize}
              TotalRecords={this.state.TotalRecords}
              ChangePageSize={this.ChangePageSize}
              ChangePage={this.ChangePage}
              CurrentRecords={this.state.MissingData.length}
            ></PaginationComponent>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    ReportData: state.ReportData.ReportData,
    UpdateDescription: state.ReportData.UpdateDescription,
    company: state.companyReducer.companyDeatils,
    ARCRSummary: state.ReportData.ARCRSummary,
    UpdateVendor: state.ReportData.UpdateVendor
  }

}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    GET_CompanyDashboard_PayrollVendorReportDetailList,
    Vendor1099Exported,
    getCompnay,
    AccountReconciliationReviewSummery,
    UpdateVendorName
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Export1099NEC);