import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { firstTimeGetPrevQuater, DropdownOption, GetSyncDateFormat, numberWithCommas, Base64 } from '../../common/commonServices';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify";
import "react-table/react-table.css";
import './CompanyDashboard.css';
//import { Table } from 'antd';
import IframeLoader from '../../common/IframeLoader';
import { LinkType } from '../../common/commonServices';
import { GET_CompanyDashboard } from '../../../appRedux/actions/CompanyDashboardAction';
import { getCompnay } from "../../../appRedux/actions/companiesAction";
import CircularProgress from "../../../components/CircularProgress";
//import { ar } from 'date-fns/esm/locale';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from '@material-ui/icons/Clear';
import Switch from '@material-ui/core/Switch';
import { Embebded_POST_updatecustomfield, PUT_QuickbookAccount_UpdateAccountStatus, UpdateDepreciationDate } from "../../../appRedux/actions/ReconcillitionAction";
import Tooltip from "@material-ui/core/Tooltip";

class CompanyDashboard extends Component {

    constructor(props) {
        super(props);

        this.sDateRef = React.createRef();
        this.depDateRef = React.createRef();
        var quater = firstTimeGetPrevQuater();
        let column = [{
            title: 'Account',
            dataIndex: 'accountName',
            className: 'table-heading',
            key: "accountName"
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            className: 'table-heading',
            key: "balance",
            align: "right",
            // render: (text) =>            
            //  <span> {numberWithCommas(text)} </span>
        },
        {
            title: 'Reconciled Through',
            dataIndex: 'reconciledThrough',
            className: 'table-heading',
            key: "reconciledThrough",
        },
        {
            title: 'Last Quarter End',
            dataIndex: 'lastReconciledEnd',
            className: 'table-heading',
            key: "lastReconciledEnd"
        }];

        var Initaldata = {
            "lstAccountReconciliation": [],
            "preCheckIssue": {
                "missingVendorNameCount": 0,
                "missingVendorNameNewCommentCount": 0,
                "missingDescriptionCount": 0,
                "missingDescriptionNewCommentCount": 0,
                "oldOutstadingCount": 0,
                "oldOutstadingNewCommentCount": 0,
                missingReferenceNumberCount: 0,
                missingReferenceNumberCommentCount: 0,
                uncategorizedTransactionsCount: 0,
                uncategorizedTransactionsCommentCount: 0,
                reconciliationDiscrepanciesCount: 0
            },
            "financialCheckup": {
                "needBetterDescriptionCount": 0,
                "needBetterDescriptionNewCommentCount": 0,
                "changeAccountNumberCount": 0,
                "changeAccountNumberNewCommentCount": 0,
                "accountsLineItemQueCommentsCount": 0,
                "accountsLineItemQueCommentsNewCommentCount": 0,
                "accountsGeneralQueCommentsCount": 0,
                "accountsGeneralQueCommentsNewCommentCount": 0,
                "reviewUpdatedTransactionDescriptionsCount": 0,
                "reviewUpdatedTransactionDescriptionsNewCommentCount": 0,
                "otherQuestionsAndCommentsCommentsCount": 2,
                "otherQuestionsAndCommentsCount": 2,
                "otherQuestionsAndCommentsTaggedAsCPACommentsCount": 0,
                "otherQuestionsAndCommentsTaggedAsCPACount": 2,
            },
            "queriesForPayroll1099": {
                "assignTaxableFringeBenefitsToAnEmployeeCount": 0,
                "employeesMissingW4Count": 0,
                "pending4361FilingStatusForClergyCount": 0,
                "pendingBoardMeetingMinutesForHousingAllowanceCount": 0,
                "pendingEmployeeUseOfVehicleFormCount": 0,
                "updateEmployeeTypeCount": 0,
                "updateVendorTypeCount": 0,
                "vendor1099PendingApprovalByClientCount": 0,

                recordEmployeePaymentInQBPayrollSystemCommentsCount: 0,
                recordEmployeePaymentInQBPayrollSystemCount: 0,
                reviewIgnoredEmployeePaymentsCommentsCount: 0,
                reviewIgnoredEmployeePaymentsTotalCount: 0,
                reviewIgnoredEmployeePaymentsCount: 0,
                resolveExcessHousingAllowanceIssuesCount: 0,
                submitEmployeeUseOfVehicleFormCount: 0,
                recordEmployeeUseofVehicleCount: 0,
                recordEmployeeUseofVehicleTotalCount: 0,
                recordEmployeePaymentInPayrollSystemCommentsCount: 0,
                recordEmployeePaymentInPayrollSystemCount: 0,
                verifyListofMinistersCount: 0,
                verifyListofMinistersTotalCount: 0,
                assignTaxableFringeBenefitToCategoryCommentsCount: 0,
                assignTaxableFringeBenefitToCategoryCount: 0,
                assignTaxableFringeBenefitToEmployeeCommentsCount: 0,
                assignTaxableFringeBenefitToEmployeeCount: 0,
                assignTaxableFringeBenefitToEmployeeTotalCount: 0,
                recordTaxableFringeBenefitInPayrollSystemCommentsCount: 0,
                recordTaxableFringeBenefitInPayrollSystemCount: 0,
                recordTaxableFringeBenefitInPayrollSystemTotalCount: 0,
                issueHousingAllowanceMemoCount: 0,
                issueHousingAllowanceMemoTotalCount: 0
            },
            queriesForVendor1099: {
                addtoEmployeeListCount: 0,
                approveWorkerClassifiedAsAnEmployeeCount: 0,
                approveWorkerClassifiedAsAnEmployeeTotalCount: 0,
                confirm1099WereMailedCount: 0,
                vendor1099MISCReadyToExportCount: 0,
                vendor1099NECReadyToExportCount: 0,
                vendor1099PendingAccountantApprovalCommentCount: 0,
                vendor1099PendingAccountantApprovalCount: 0,
                vendor1099PendingClientApprovalCommentCount: 0,
                vendor1099PendingClientApprovalCount: 0,
                vendor1099StatusReportCount: 0,
                vendorsMissingW9Count: 0,
                resolve1099VendorNameDiscrepanciesCount: 0,
                resolve1099VendorNameDiscrepanciesCommentCount: 0,
            },
            allLastReviewedDatesModel: {
                lastGeneralLedgerReviewedDate: null,
                lastBalanceSheetReviewedDate: null,
                last1099ComplianceReviewedDate: null,
                lastDateDepreciationWasPosted: null
            }
        };

        var displayQT = quater.quater + " Quarter, " + quater.year;
        var sDate = new Date(2019, 0, 1);
        var eDate = new Date();
        let user = JSON.parse(localStorage.getItem('userData'));
        this.state = {
            Title: user.companyName,
            companyId: 0,
            quater: quater,
            loading: false,
            getDashboardData: Initaldata,
            columns: column,
            IsDateModelOpen: false,
            IsEmbeddedModelOpen: false,
            defaultSelection: { value: 'QT', label: 'Quarterly' },
            selectedDateRange: { startDate: sDate, endDate: eDate },
            displayText: displayQT,
            previousSelection: { value: 'QT', label: 'Quarterly' },
            LastUpdated: "",
            IframeURL: window.location.origin + "/companyDashboard",
            IframeURLBookkeeping: window.location.origin + "/companyBookkeepingDashboard",
            ShowRecEmpPayInPayrollCount: false,
            showRecEmpPayInQB: false,
            ShowVerifyMinister: false,
            ShowRecEmpThirdParty: false,
            IsBookkeepingClient: false,
            companyName: "",
            reconciledThroughDate: new Date(2019, 0, 1),
            editIndex: -1,
            errorMsg: null,
            DashboardData: false,
            clientViewUrl: window.location.origin + "/companyDashboard?q=" + Base64.encode(user.companyId.toString()) + "&p=" + Base64.encode(user.companyName),
            editDepreciationIndex: -1,
            depreciationPostedDate: new Date()
        };
    }


    SetQuater = (quater) => {
        if (quater != null) {

            let selectedItem = DropdownOption.filter(function (item) {
                return item.value === "QT";
            });
            var displayQT = quater.quater + " Quarter, " + quater.year;
            this.setState({ quater: quater, IsDateModelOpen: false, selectedDateRange: { startDate: quater.quarterStartDate, endDate: quater.quarterEndDate }, displayText: displayQT, previousSelection: selectedItem });
        }
    }

    HideModel = () => {
        this.setState({ IsDateModelOpen: false });
    }

    ShowDateOption = (e) => {

        var selectedItem = DropdownOption.filter(function (item) {
            return item.value === e.value;
        });

        if (e.value === "YTD") {
            this.setState({ IsYTDSelected: true, IsQuaterlySelected: false, defaultSelection: selectedItem, IsDateModelOpen: true });//,previousSelection:previousOption
        }
        else if (e.value === "QT") {
            this.setState({ IsYTDSelected: false, IsQuaterlySelected: true, defaultSelection: selectedItem, IsDateModelOpen: true });//,previousSelection:previousOption 
        }
        else {
            this.setState({ IsYTDSelected: false, IsQuaterlySelected: false, defaultSelection: "" });
        }
    }

    onDateModelCancel = (range) => {
        if (range != null) {
            let selectedItem = DropdownOption.filter(function (item) {
                return item.value === "YTD";
            });
            var displayYTD = moment(range.startDate).format("MM/DD/YY") + " - " + moment(range.uptoDate).format("MM/DD/YY");
            this.setState({ selectedDateRange: { startDate: range.startDate, endDate: range.uptoDate }, displayText: displayYTD, previousSelection: selectedItem });
        }
        else {
            this.setState({ defaultSelection: this.state.previousSelection });
        }
        this.setState({ IsDateModelOpen: false });
    }

    onRefreshDataClick = () => {
        if (!this.state.loading) {
            this.setState({ loading: true, DashboardData: true }, () => {
                this.getDashboardData(true);
            });
        }
    }

    CloseModel = () => {
        this.setState({ IsEmbeddedModelOpen: false });
    }

    OpenEmbeddedModel = () => {
        this.setState({ IsEmbeddedModelOpen: true });
    }

    getDashboardData(IsRefresh = false) {

        let state = this.state;
        let body = {
            companyId: state.companyId,
            fromDate: moment(state.selectedDateRange.startDate).format("MM/DD/YYYY"),
            toDate: moment(state.selectedDateRange.endDate).format("MM/DD/YYYY"),
            NeedUpdatQBORecords: IsRefresh
        }

        this.props.GET_CompanyDashboard(body);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.companyDashboardData !== undefined && nextProps.companyDashboardData !== this.props.companyDashboardData) {
            var returnObj = nextProps.companyDashboardData;
            if (returnObj.transactionStatus === 0) {
                var ShowRecEmpPay = false;
                var showEmpPayInQB = false;
                var showMinister = false;
                var showEmpThirdParty = false;
                var BookkeepingClient = false;
                try {
                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        (returnObj.returnObject.company.payrollSoftware === "ADP Full Service Payroll"
                            || returnObj.returnObject.company.payrollSoftware === "Other Full Service Payroll"
                        )
                    ) {
                        ShowRecEmpPay = true;
                    }

                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook") > -1
                    ) {
                        showEmpPayInQB = true;
                    }

                    if (returnObj.returnObject.company.payrollSoftware != null &&
                        (returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook") === -1
                            && returnObj.returnObject.company.payrollSoftware != "None"
                        )
                    ) {
                        //var a = returnObj.returnObject.company.payrollSoftware.toLowerCase().indexOf("quickbook");
                        showEmpThirdParty = true;
                    }

                    if (returnObj.returnObject.company.isReligeousOrganization != null) {
                        showMinister = returnObj.returnObject.company.isReligeousOrganization;
                    }
                    if (returnObj.returnObject.company.isFullServiceBookkeeping != null) {
                        BookkeepingClient = returnObj.returnObject.company.isFullServiceBookkeeping;
                    }

                    if (returnObj.returnObject.lstAccountReconciliation != null && returnObj.returnObject.lstAccountReconciliation.length > 0) {
                        var arData = returnObj.returnObject.lstAccountReconciliation;
                        for (let i = 0; i < arData.length; i++) {
                            arData[i].balance = numberWithCommas(arData[i].balance);
                        }
                        returnObj.returnObject.lstAccountReconciliation = arData;
                    }

                    // if(returnObj.returnObject.company.quickbooksDataSyncEndingYear != "" && returnObj.returnObject.company.quickbooksDataSyncEndingYear!=null) {
                    //     sessionStorage.setItem('endingYear', returnObj.returnObject.company.quickbooksDataSyncEndingYear);
                    // }
                }
                catch (err) {

                }
                this.setState({
                    getDashboardData: returnObj.returnObject,
                    ShowRecEmpPayInPayrollCount: ShowRecEmpPay,
                    showRecEmpPayInQB: showEmpPayInQB,
                    ShowVerifyMinister: showMinister,
                    ShowRecEmpThirdParty: showEmpThirdParty,
                    IsBookkeepingClient: BookkeepingClient,
                    LastUpdated: GetSyncDateFormat(nextProps.companyDashboardData.returnObject.lastSyncDate)
                }, () => {
                    this.setState({ loading: false, DashboardData: false });
                });
            } else {
                this.setState({ loading: false, DashboardData: false }, () => {
                    toast.error(`${returnObj.resultMsg}`);
                });
            }
        }
        if (nextProps.company !== undefined && nextProps.company !== this.props.company) {
            let name = this.state.companyName;

            if (nextProps.company != null) {
                name = nextProps.company.name + (nextProps.company.city != null ? ", " + nextProps.company.city : "") + (nextProps.company.state != null ? ", " + nextProps.company.state : "");
            }

            this.setState({ loading: false, Title: name });
        }

        if (nextProps.Update_reconciled !== undefined && nextProps.Update_reconciled !== this.props.Update_reconciled) {
            this.setState({ loading: true, DashboardData: true, editIndex: -1 }, () => {
                this.getDashboardData();
            });
        }

        if (nextProps.Update_depreciation !== undefined && nextProps.Update_depreciation !== this.props.Update_depreciation) {
            this.setState({ loading: true, DashboardData: true, editDepreciationIndex: -1 }, () => {
                this.getDashboardData();
            });
        }

        if (nextProps.account_Inactive !== undefined && nextProps.account_Inactive !== this.props.account_Inactive) {
            this.setState({ loading: true, DashboardData: true, editIndex: -1 }, () => {
                this.getDashboardData();
            });
        }

        let user = JSON.parse(localStorage.getItem('userData'));
        if (user.companyId !== this.state.companyId) {
            this.setState({
                companyId: user.companyId,
                loading: true,
                DashboardData: true
            }, () => {
                this.getDashboardData();
                this.props.getCompnay(user.companyId);
            });
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('userData'));

        this.setState({
            companyId: user.companyId,
            loading: true,
            DashboardData: true
        }, () => {
            this.getDashboardData();
            this.props.getCompnay(user.companyId);
        });
    }

    DownloadReport = () => {
        toast.success("This will be Coming soon");
    }

    editReconcilThroughDate = (e, index, item) => {

        this.setState({ editIndex: index, reconciledThroughDate: item.reconciledThrough && (item.reconciledThrough.toLowerCase()).indexOf("never") < -1 && (item.reconciledThrough.toLowerCase()).indexOf("pending") < -1 ? new Date(item.reconciledThrough) : new Date() });
    }

    editDepreciationDate = (e) => {
        this.setState({
            editDepreciationIndex: 0,
            depreciationPostedDate: this.state.getDashboardData.allLastReviewedDatesModel.lastDateDepreciationWasPosted
                ? moment(this.state.getDashboardData.allLastReviewedDatesModel.lastDateDepreciationWasPosted).toDate()
                : new Date()
        });
    };

    onClickCloseDepreButtonDate = (e) => {
        this.setState({
            editDepreciationIndex: -1
        });
    };

    handleDateChange = (e, target) => {
        if (this.state.editIndex > -1) {
            const editIndex = this.state.editIndex;
            if (target === "fromDate") {
                this.setState({ reconciledThroughDate: new Date(e) });
            }
        }
        else if (this.state.editDepreciationIndex > -1) {
            const editDepreciationIndex = this.state.editDepreciationIndex
            if (target === "depreciationDate") {
                this.setState({ depreciationPostedDate: new Date(e) });
            }
        }
    }

    handleSaveDateChange = (e, target) => {
        if (target === "fromDate" && this.state.editIndex > -1) {
            const editIndex = this.state.editIndex;
            var state = [...this.state.getDashboardData.lstAccountReconciliation];

            var obj = {
                "accountId": state[editIndex].accountID,
                "reconciledThrough": moment(this.state.reconciledThroughDate).format('YYYY-MM-DD'),
                "isExcludedReconciliation": state[editIndex].isExcludedReconciliation
            };

            // Call the API to update reconciledThroughDate
            this.setState({ loading: true }, () => {
                this.props.Embebded_POST_updatecustomfield(obj);
            });

        } else if (target === "depreciationDate" && this.state.editDepreciationIndex > -1) {
            console.log("depreciationDate",this.state.depreciationPostedDate)
            const editDepreciationIndex = this.state.editDepreciationIndex;
            var state = [...this.state.getDashboardData.lstAccountReconciliation];

            const companyId = this.state.companyId; // Assuming companyId is in the state
            const depreciationUpdatedDate = moment(this.state.depreciationPostedDate).format('YYYY-MM-DD');

            // Call the API to update depreciationDate
            this.setState({ loading: true }, () => {
                this.props.UpdateDepreciationDate(companyId, depreciationUpdatedDate);
            });
        }
    };

    handleDateChangeInput = (e, target) => {
        const isValidDate = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(e.target.value);
        if (isValidDate) {
            if (this.state.editIndex > -1) {
                const editIndex = this.state.editIndex;
                const state = [...this.state.getDashboardData.lstAccountReconciliation];

                if (target === "fromDate") {
                    this.setState({ reconciledThroughDate: new Date(e.target.value) }, () => {
                        const obj = {
                            "accountId": state[editIndex].accountID,
                            "reconciledThrough": moment(this.state.reconciledThroughDate).format('YYYY-MM-DD'),
                            "isExcludedReconciliation": state[editIndex].isExcludedReconciliation
                        };
                        this.setState({ loading: true }, () => {
                            this.props.Embebded_POST_updatecustomfield(obj);
                        });
                    });
                }
            }
            else if (this.state.editDepreciationIndex > -1) {
                if (target === "depreciationDate") {
                    this.setState({ depreciationPostedDate: new Date(e.target.value) }, () => {
                        const companyId = this.state.companyId;
                        const depreciationUpdatedDate = moment(this.state.depreciationPostedDate).format('YYYY-MM-DD');

                        this.setState({ loading: true });
                        this.props.UpdateDepreciationDate(companyId, depreciationUpdatedDate, () => {
                            // Reset editDepreciationIndex after API call completes
                            this.setState({ editDepreciationIndex: -1, loading: false });
                        });
                    });
                }
            }
        }
    }

    handleFocus = (e, selecter) => {
        let control = null;
        if (selecter !== undefined) {
            if (selecter === "sDate") {
                control = this.sDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
            else if (selecter == "depreciationDate") {
                control = this.depDateRef;
                if (control.current != null) {
                    control.current.setFocus();
                }
            }
        }
    }

    onClickCloseButtonDate = (e) => {
        this.setState({ editIndex: -1, reconciledThroughDate: new Date() });
    }

    accountStatusChange = (event, item, index) => {
        let isChecked = event.target.checked;
        if (parseInt(item.balance) !== 0 && !isChecked) {
            // toast.error("Error- This Account has a non-zero balance. you can not make as inactive.");
            this.setState({ errorMsg: "This account has a balance on your books. You may make this account inactive after the account balance is zero." }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: null });
                }, 6000);
            });
            //toast.error("Error- This account has a balance on your books  You may make this account inactive after the account balance is zero");
            return false;
        }
        let body = {
            "accountId": item.accountID,
            "isActive": isChecked
        }
        this.setState({ loading: true }, () => {
            this.props.PUT_QuickbookAccount_UpdateAccountStatus(body);
        });
    }

    render() {
        var userData = JSON.parse(localStorage.getItem('userData'));
        var preCheckIssue = this.state.getDashboardData.preCheckIssue;
        var financialCheckup = this.state.getDashboardData.financialCheckup;
        var allLastReviewedDatesModel = this.state.getDashboardData.allLastReviewedDatesModel;
        var FullBookkeepingClient = this.state.IsBookkeepingClient;

        return (
            <React.Fragment>
                <div className="dashboardPage">
                    <div className="page-heading page-title-box d-sm-flex justify-content-sm-between align-items-sm-center border-bottom">
                        <h2 className="title mb-3 mb-sm-0">{this.state.Title}</h2>
                    </div>
                    {this.state.loading || this.state.DashboardData ? <div style={{ minHeight: 'calc(100vh - 155px)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}> <CircularProgress /></div> :
                        <div className="padding-box app-wrapper">
                            <div className="row">
                                {
                                    this.state.IsEmbeddedModelOpen ?
                                        <IframeLoader
                                            URL={FullBookkeepingClient ? this.state.IframeURLBookkeeping : this.state.IframeURL}
                                            CloseModel={this.CloseModel}
                                        > </IframeLoader> :
                                        ""
                                }
                                {/* 
                                <div className="col-md-12">
                                    <div className="header-btn-box">
                                        <div className="dashborard-header-btn-box">
                                        <Button href={window.location.origin + "/companyDashboard?q="+Base64.encode(userData.companyId.toString())+"&p="+Base64.encode(userData.companyName)} variant="raised" className="jr-btn bg-teal text-white  refresh-btn schedule-appointment" target='_blank' >Client View</Button>
                                            <Button className="share-btn jr-btn bg-teal text-white refresh-btn" onClick={() => this.OpenEmbeddedModel()} ><i className="zmdi zmdi-share"></i>
                                                share</Button>
                                            <Button className="jr-btn bg-teal text-white refresh-btn greencolor" onClick={() => this.onRefreshDataClick()}><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                                        </div>
                                        <div className="last-update-box">
                                            <div className="content "><p>Last Update Date &amp; Time</p><span className="Font15PX">{this.state.LastUpdated}</span></div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            <div className="row company_Dashboard_comment_count">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4" >

                                    <div className="header-btn-box">
                                        <div className="dashborard-header-btn-box">
                                            {FullBookkeepingClient ?
                                                <Button href={window.location.origin + "/companyBookkeepingDashboard?q=" + Base64.encode(userData.companyId.toString()) + "&p=" + Base64.encode(userData.companyName)} variant="raised" className="jr-btn bg-teal text-white  refresh-btn schedule-appointment" target='_blank' >Client View</Button>
                                                :
                                                <Button href={window.location.origin + "/companyDashboard?q=" + Base64.encode(userData.companyId.toString()) + "&p=" + Base64.encode(userData.companyName)} variant="raised" className="jr-btn bg-teal text-white  refresh-btn schedule-appointment" target='_blank' >Client View</Button>
                                            }
                                            <Button className="share-btn jr-btn bg-teal text-white refresh-btn" onClick={() => this.OpenEmbeddedModel()} ><i className="zmdi zmdi-share"></i>
                                                Share</Button>
                                            <Button className="jr-btn bg-teal text-white refresh-btn greencolor" onClick={() => this.onRefreshDataClick()}><i className="zmdi zmdi-refresh "></i>Refresh Data</Button>
                                        </div>
                                        <div className="last-update-box">
                                            <div className="content "><p>Last Update Date &amp; Time</p><span className="Font15PX">{this.state.LastUpdated}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                                <p style={{marginBottom:"-5px",fontWeight:"500"}}>Last General Review Period End Date: <span style={{marginLeft:"70px"}}> {allLastReviewedDatesModel.lastGeneralLedgerReviewedDate !=null ? moment(allLastReviewedDatesModel.lastGeneralLedgerReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                                <p style={{marginBottom:"-5px",fontWeight:"500"}}>Last Balance Sheet Review Period End Date: <span style={{marginLeft:"28px"}}> {allLastReviewedDatesModel.lastBalanceSheetReviewedDate !=null ? moment(allLastReviewedDatesModel.lastBalanceSheetReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                                <p style={{fontWeight:"500"}}>Last 1099 Compliance Review Date: <span style={{marginLeft:"79px"}}>{allLastReviewedDatesModel.last1099ComplianceReviewedDate !=null ? moment(allLastReviewedDatesModel.last1099ComplianceReviewedDate).format("MM/DD/YYYY") : ""}</span></p>
                            </div> */}

                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                                    <div className="page-heading panel-box">
                                        <h2 className="panel-title panel-bg">
                                            <img src="vendors/Tick-mark.png" alt="checkmark" className="check-icon"></img> Financial Statements

                                        </h2>
                                        <div className="panel-detail-box">
                                            <div className="row">
                                                {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/Payroll-1099-Compliance-Issues.png"></img>
                                                        <h2 className="panel-sub-title">Payroll</h2>
                                                        <p className="panel-parag">Compliance Issues</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12" >
                                                    <a className="panel-detail" href='https://app.qbo.intuit.com/app/reportv2?token=PANDL' target='_blank'>
                                                        <div className="panel-detail-content col-md-11">
                                                            <p> Profit and Loss Statement </p>
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/Link-gray.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Link-darkBlue.png"></img>
                                                        </div>
                                                    </a>

                                                </div>
                                                <div className="col-md-12" >
                                                    <a className="panel-detail" href='https://app.qbo.intuit.com/app/reportv2?token=BAL_SHEET' target='_blank'>
                                                        <div className="panel-detail-content col-md-11">
                                                            <p> Balance Sheet </p>
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/Link-gray.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Link-darkBlue.png"></img>
                                                        </div>
                                                    </a>

                                                </div><div className="col-md-12" >
                                                    <div className="panel-detail panel-detail-content col-md-12">
                                                        <p> Please request our team to review your financial reports before you submit them to your board or any financial institution.
                                                            Make sure all accounts are reconciled and financial check-up issues have been resolved.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="page-heading panel-box">
                                        <div className='clientdash-reconcilation d-flex panel-title panel-bg'>
                                            <h2>
                                                <img src="/vendors/Tick-mark.png" alt="checkmark" className="check-icon"></img>Compliance Reviews                                              {/* Pending Current Quarter Account Reconciliations */}
                                            </h2>

                                        </div>

                                        <div className="panel-detail-box">
                                            <div className="panel-detail" >
                                                <div className="panel-detail-content col-md-9">
                                                    <p>Last General Review Period End Date:</p>
                                                </div>
                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                    {allLastReviewedDatesModel.lastGeneralLedgerReviewedDate != null ? moment(allLastReviewedDatesModel.lastGeneralLedgerReviewedDate).format("MM/DD/YYYY") : ""}
                                                </div>
                                            </div>
                                            <div className="panel-detail" >
                                                <div className="panel-detail-content col-md-9">
                                                    <p>Last Balance Sheet Review Period End Date:</p>
                                                </div>
                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                    {allLastReviewedDatesModel.lastBalanceSheetReviewedDate != null ? moment(allLastReviewedDatesModel.lastBalanceSheetReviewedDate).format("MM/DD/YYYY") : ""}
                                                </div>
                                            </div>
                                            <div className="panel-detail" >
                                                <div className="panel-detail-content col-md-9">
                                                    <p>Last 1099 Compliance Review Date:</p>
                                                </div>
                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                    {allLastReviewedDatesModel.last1099ComplianceReviewedDate != null ? moment(allLastReviewedDatesModel.last1099ComplianceReviewedDate).format("MM/DD/YYYY") : ""}
                                                </div>
                                            </div>

                                            <div className="panel-detail">
                                                <div className="panel-detail-content col-md-9">
                                                    <p>Depreciation Has Been Posted Through:</p>
                                                </div>
                                                <div className="right-icon col-md-3 text-right compliance-review-text">
                                                    <div class>
                                                        {this.state.editDepreciationIndex === -1 ? (
                                                            <span className="date-display" onClick={() => this.setState({ editDepreciationIndex: 0 })}>
                                                                {this.state.getDashboardData.allLastReviewedDatesModel.lastDateDepreciationWasPosted = moment(this.state.getDashboardData.allLastReviewedDatesModel.lastDateDepreciationWasPosted).format("MM/DD/YYYY")}
                                                            </span>
                                                        ) : (
                                                            <div className="datepicker-box">
                                                                <div className="date-box">
                                                                    <DatePicker
                                                                        selected={this.state.depreciationPostedDate}
                                                                        onChange={(date) => this.setState({ depreciationPostedDate: date })}
                                                                        name="depreciationDate"
                                                                        id="date"
                                                                        required
                                                                        disabledKeyboardNavigation
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        yearDropdownItemNumber={5}
                                                                        ref={this.depDateRef}
                                                                    />
                                                                    <div className="date-icon account-status-date-icon" onClick={(e) => this.handleFocus(e, "depreciationDate")}>
                                                                        <i className="zmdi zmdi-calendar-note"></i>
                                                                    </div>
                                                                </div>
                                                                <Button className="jr-btn close-btn custom-edit-btn" onClick={(e) => this.handleSaveDateChange(e, 'depreciationDate')}><i className="zmdi zmdi-check zmdi-hc-lg"></i></Button>
                                                                <Button className="jr-btn close-btn" style={{ right: "-8px" }} onClick={(e) => { e.stopPropagation(); this.setState({ editDepreciationIndex: -1 }); }}><ClearIcon /></Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-heading panel-box">
                                        <h2 className="panel-title panel-bg">
                                            <img alt="" src="/vendors/Tick-mark.png" className="check-icon"></img> Account Reconciliation Status</h2>
                                        <div className="panel-detail-box">
                                            {/* <div className="account-box">
                                                <div className="account-img">
                                                    <img alt="" src="/vendors/Account-Reconciliation-Status.png"></img>
                                                </div>
                                                <div className="account-title-box" style={{ marginLeft: 20 }}>
                                                    <h2 className="panel-sub-title accuntant-text-color">Account</h2>
                                                    <p className="panel-parag">Reconciliation Status</p>
                                                </div>
                                            </div> */}
                                            {/* <div className="table-box" style={{ marginTop: "20px" }}>
                                                <div >
                                                    <Table columns={this.state.columns}
                                                        dataSource={this.state.getDashboardData.lstAccountReconciliation}
                                                        bordered={true}
                                                        loading={this.state.loading}
                                                        pagination={false}
                                                        size={"small"}
                                                    >
                                                    </Table>
                                                </div>
                                            </div> */}
                                            <div className="table-box table-responsive account-status-table" style={{ marginTop: "15px" }}>
                                                <div>
                                                    {/* className="dashboardReconcilitationGrid" */}
                                                    {/* <Table columns={this.state.columns}
                                                            dataSource={this.state.getDashboardData.lstAccountReconciliation}
                                                            bordered={true}
                                                            loading={this.state.loading}
                                                            pagination={false}
                                                            size={"small"}
                                                        >
                                                        </Table> */}
                                                    {this.state.errorMsg && (<div className="mt-3 mb-3 alert alert-dark fade show">
                                                        {this.state.errorMsg}
                                                    </div>)}
                                                    <table className="table client_company_dashboard">
                                                        <tr>
                                                            <th className="custome-table-border"> Account</th>
                                                            <th className="custome-table-border"> Balance</th>
                                                            <th className="custome-table-border reconciled-th"> Reconciled Through</th>
                                                            <th className="custome-table-border last-quarter-end"> Last Quarter End</th>
                                                            <th className="custome-table-border account-status"> Account Status</th>
                                                        </tr>
                                                        <tbody>
                                                            {this.state.getDashboardData.lstAccountReconciliation && this.state.getDashboardData.lstAccountReconciliation.map((item, index) => (
                                                                <tr>
                                                                    <Tooltip placement="top" title={item.accountName} style={{ top: "10px !important" }}>
                                                                        <td style={{ border: "1px solid #e8e8e8 !important" }} >
                                                                            {item.accountName}
                                                                        </td>
                                                                    </Tooltip>
                                                                    <td style={{ border: "1px solid #e8e8e8 !important", textAlign: "right" }} >{item.balance}</td>
                                                                    <td style={{ border: "1px solid #e8e8e8 !important" }}>{this.state.editIndex === index ? '' : item.reconciledThrough}
                                                                        {(this.state.editIndex === -1) &&
                                                                            <span className=""
                                                                                onClick={(e) => this.editReconcilThroughDate(e, index, item)}>
                                                                                <i className="zmdi zmdi-edit zmdi-hc-lg ml-4 pointer"></i></span>}
                                                                        {(this.state.editIndex === index) &&
                                                                            <React.Fragment>
                                                                                <div className="datepicker-box">
                                                                                    <div className="date-box">
                                                                                        <DatePicker
                                                                                            selected={this.state.reconciledThroughDate}
                                                                                            // onChangeRaw={(e) => this.handleDateChangeInput(e, 'fromDate')}
                                                                                            onSelect={(e) => this.handleDateChange(e, 'fromDate')}
                                                                                            // onChange={(e) => this.handleDateChangeInput(e, 'fromDate')}
                                                                                            name="fromDate"
                                                                                            id="date"
                                                                                            required={true}
                                                                                            margin="normal"
                                                                                            disabledKeyboardNavigation
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            yearDropdownItemNumber={5}
                                                                                            // maxDate={this.state.eDate}
                                                                                            ref={this.sDateRef}
                                                                                        />
                                                                                        <div className="date-icon account-status-date-icon" onClick={(e) => this.handleFocus(e, 'sDate')}>
                                                                                            <i className="zmdi zmdi-calendar-note"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Button className="jr-btn close-btn custom-edit-btn" onClick={(e) => this.handleSaveDateChange(e, 'fromDate')}><i className="zmdi zmdi-check zmdi-hc-lg"></i></Button>
                                                                                    <Button className="jr-btn close-btn" style={{ right: "-8px" }} onClick={(e) => this.onClickCloseButtonDate(e)}><ClearIcon /></Button>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </td>
                                                                    <td style={{ border: "1px solid #e8e8e8 !important" }} >{item.lastReconciledEnd}</td>
                                                                    <td className="custome-table-border">
                                                                        <Switch className={item.isActive ? 'switch-box-blue' : 'switch-box-blue switch-inactive'} size="small"
                                                                            checked={item.isActive} onChange={(e) => this.accountStatusChange(e, item, index)} />
                                                                        <span style={{ color: '#273f4c' }} className={item.isActive ? '' : ''}>{item.isActive ? "Active" : "Inactive"}</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-heading panel-box payroll-comliance-box" >
                                        <h2 className="panel-title bg-color-panel"><img alt="" src="/vendors/Tick-mark.png" className="check-icon"></img> Payroll Compliance Issues</h2>
                                        <div className="panel-detail-box">
                                            <div className="row">
                                                {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/Payroll-1099-Compliance-Issues.png"></img>
                                                        <h2 className="panel-sub-title">Payroll</h2>
                                                        <p className="panel-parag">Compliance Issues</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12" >
                                                    {!this.state.ShowRecEmpPayInPayrollCount &&
                                                        <Link className="panel-detail" to={{ pathname: '/employeeMissW4', state: { type: LinkType.EmployeeMissW4, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-11">
                                                                <h4>{this.state.getDashboardData.queriesForPayroll1099.employeesMissingW4Count}</h4>
                                                                <p> {LinkType.EmployeeMissW4}</p>
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>}
                                                    {this.state.ShowRecEmpThirdParty &&
                                                        <React.Fragment>
                                                            <Link className="panel-detail" to={{ pathname: '/recordCheckPC', state: { type: LinkType.RecordCheckPC, parentState: this.state } }}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCount}</h4>
                                                                    <p>  Record Check in Payroll System</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </Link>
                                                        </React.Fragment>
                                                    }
                                                    {this.state.showRecEmpPayInQB &&
                                                        <React.Fragment>
                                                            <Link className="panel-detail" to={{ pathname: '/EmployeePayment', state: { type: LinkType.RecEmpPayment, parentState: this.state } }}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCount}</h4>
                                                                    <p> Record Employee Payment in QB Payroll System</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInQBPayrollSystemCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </Link>
                                                            <Link className="panel-detail" to={{ pathname: '/IgnoreEmpPayment', state: { type: LinkType.IgnoreEmpPayment, parentState: this.state } }}>
                                                                <div className="panel-detail-content col-md-9">
                                                                    {/* <h4>{this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsCount}
                                                                        /{this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsTotalCount}
                                                                    </h4> */}

                                                                    <h4>{this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsCount}</h4>

                                                                    <p> Review Ignored Employee Payments</p>
                                                                </div>
                                                                <div className="panel-commentCount col-md-2" >
                                                                    <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                    {this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.reviewIgnoredEmployeePaymentsCommentsCount}</span>}
                                                                </div>
                                                                <div className="right-icon col-md-1">
                                                                    <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                    <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                                </div>
                                                            </Link>
                                                        </React.Fragment>
                                                    }
                                                    <Link className="panel-detail" to={{ pathname: '/HousingAllowance', state: { type: LinkType.HousingAllowanceIssues, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForPayroll1099.resolveExcessHousingAllowanceIssuesCount}</h4>
                                                            <p> Resolve Excess Housing Allowance Issues</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0/0</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/EmpVehicleForm', state: { type: LinkType.EmployeeVehicleForm, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForPayroll1099.submitEmployeeUseOfVehicleFormCount}</h4>
                                                            <p>{LinkType.EmployeeVehicleForm}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/vehicleuse', state: { type: LinkType.RecordEmpVehicleUse, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            {/* <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeeUseofVehicleCount}/{this.state.getDashboardData.queriesForPayroll1099.recordEmployeeUseofVehicleTotalCount}</h4> */}
                                                            <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeeUseofVehicleCount}</h4>
                                                            <p> {LinkType.RecordEmpVehicleUse}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/addemplist', state: { type: LinkType.AddToEmployeeList, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.addtoEmployeeListCount}</h4>
                                                            <p> {LinkType.AddToEmployeeList}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>

                                                    {this.state.ShowRecEmpPayInPayrollCount &&
                                                        <Link className="panel-detail" to={{ pathname: '/recemppay', state: { type: LinkType.RecEmpPayPayroll, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCount}</h4>
                                                                <p> {LinkType.RecEmpPayPayroll}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordEmployeePaymentInPayrollSystemCommentsCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    }
                                                    {this.state.ShowVerifyMinister &&
                                                        <Link className="panel-detail" to={{ pathname: '/ministerlist', state: { type: LinkType.MinisterList, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                {/* <h4>{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersCount}/{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersTotalCount}</h4> */}
                                                                <h4>{this.state.getDashboardData.queriesForPayroll1099.verifyListofMinistersCount}</h4>
                                                                <p> {LinkType.MinisterList}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0</span>} */}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    }
                                                    <Link className="panel-detail" to={{ pathname: '/approveworker', state: { type: LinkType.ApproveWorker, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            {/* <h4>{this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeCount}/
                                                            {this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeTotalCount}</h4> */}

                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.approveWorkerClassifiedAsAnEmployeeCount}</h4>

                                                            <p> {LinkType.ApproveWorker}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/AssignTFBEMP', state: { type: LinkType.AssignTFBToEmp, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            {/* <h4> {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCount} /
                                                                    {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeTotalCount}
                                                            </h4> */}
                                                            <h4> {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCount}
                                                            </h4>
                                                            <p> {LinkType.AssignTFBToEmp}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.assignTaxableFringeBenefitToEmployeeCommentsCount}</span>}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>

                                                    <Link className="panel-detail" to={{ pathname: '/TFBPayroll', state: { type: LinkType.TFBInPayroll, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            {/* <h4>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCount}/
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemTotalCount}

                                                            </h4> */}
                                                            <h4>
                                                                {this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCount}
                                                            </h4>
                                                            <p> {LinkType.TFBInPayroll}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.recordTaxableFringeBenefitInPayrollSystemCommentsCount}</span>}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/HAMEMO', state: { type: LinkType.IssueHAMemo, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            {/* <h4>{this.state.getDashboardData.queriesForPayroll1099.issueHousingAllowanceMemoCount}/
                                                            {this.state.getDashboardData.queriesForPayroll1099.issueHousingAllowanceMemoTotalCount}
                                                            </h4> */}
                                                            <h4>{this.state.getDashboardData.queriesForPayroll1099.issueHousingAllowanceMemoCount}
                                                            </h4>
                                                            <p> {LinkType.IssueHAMemo}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.recordCheckInPayrollSystemCommentsCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">0</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="page-heading panel-box pre-check-box" >
                                        <h2 className="panel-title" > <img alt="" src="/vendors/Tick-mark.png" className="check-icon"></img> Data Entry Issues</h2>
                                        <div className="panel-detail-box">
                                            <div className="row">
                                                {/* <div className="col-12">
                                                    <div className="panel-detail">
                                                        <p> Includes transactions dated 01/01/2019 through today.</p>
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ color: "#ff7600" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/PreCheckNewLogo.png"></img>
                                                        <h2 className="panel-sub-title">DATA ENTRY </h2>
                                                        <p className="panel-parag">ISSUES</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div >
                                                        <Link className="panel-detail" to={{ pathname: '/tranMissVen', state: { type: LinkType.TranMissVendor, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.missingVendorNameCount} <div className='lastReviewDate'>{preCheckIssue.lastTransactionsMissingVendorNamesWereReviewed ? `Last reviewed on ${preCheckIssue.lastTransactionsMissingVendorNamesWereReviewed}` : ''}</div></h4>
                                                                <p>{LinkType.TranMissVendor}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" style={{ marginLeft: 'auto' }}>
                                                                <img style={{ height: '25px' }} className={preCheckIssue.missingVendorNameNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.missingVendorNameNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.missingVendorNameNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div >
                                                        <Link className="panel-detail" to={{ pathname: '/tranMissDes', state: { type: LinkType.TranMissDescription, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.missingDescriptionCount}<div className='lastReviewDate'>{preCheckIssue.lastTransactionsMissingDescriptionsWereReviewed ? `Last reviewed on ${preCheckIssue.lastTransactionsMissingDescriptionsWereReviewed}` : ''}</div></h4>
                                                                <p>{LinkType.TranMissDescription}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" style={{ marginLeft: 'auto' }}>
                                                                <img style={{ height: '25px' }} className={preCheckIssue.missingDescriptionNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.missingDescriptionNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.missingDescriptionNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div >
                                                        <Link className="panel-detail" to={{ pathname: '/ooTran', state: { type: LinkType.OOTran, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.oldOutstadingCount}<div className='lastReviewDate'>{preCheckIssue.lastOldOutstandingTransactionsWereReviewed ? `Last reviewed on ${preCheckIssue.lastOldOutstandingTransactionsWereReviewed}` : ''}</div></h4>
                                                                <p>{LinkType.OOTran}</p>
                                                            </div>
                                                            {/* style={{ marginLeft: 'auto' }} */}
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={preCheckIssue.oldOutstadingNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.oldOutstadingNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{preCheckIssue.oldOutstadingNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div >
                                                        <Link className="panel-detail" to={{ pathname: '/MissRefNo', state: { type: LinkType.MissRefNo, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.missingReferenceNumberCount}<div className='lastReviewDate'>{preCheckIssue.lastTransactionsMissingACheckNumberWereReviewed ? `Last reviewed on ${preCheckIssue.lastTransactionsMissingACheckNumberWereReviewed}` : ''}</div></h4>
                                                                <p> {LinkType.MissRefNo}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={preCheckIssue.missingReferenceNumberCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.missingReferenceNumberCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{preCheckIssue.missingReferenceNumberCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div >
                                                        <Link className="panel-detail" to={{ pathname: '/UCT', state: { type: LinkType.UnCategorizeTransaction, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.uncategorizedTransactionsCount}<div className='lastReviewDate'>{preCheckIssue.lastUncategorizedTransactionsWereReviewed ? `Last reviewed on ${preCheckIssue.lastUncategorizedTransactionsWereReviewed}` : ''}</div></h4>
                                                                <p> {LinkType.UnCategorizeTransaction}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={preCheckIssue.uncategorizedTransactionsCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.uncategorizedTransactionsCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{preCheckIssue.uncategorizedTransactionsCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>


                                                        <Link className="panel-detail" to={{ pathname: '/RRD', state: { type: LinkType.ReviewReconciliationDiscrepancies, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4 style={{ display: 'flex', alignItems: 'center' }}>{preCheckIssue.reconciliationDiscrepanciesCount}<div className='lastReviewDate'>{preCheckIssue.lastReconciliationDiscrepanciesWereReviewed ? `Last reviewed on ${preCheckIssue.lastReconciliationDiscrepanciesWereReviewed}` : ''}</div></h4>
                                                                <p> {LinkType.ReviewReconciliationDiscrepancies}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={preCheckIssue.reconciliationDiscrepanciesCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                                {preCheckIssue.reconciliationDiscrepanciesCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{preCheckIssue.reconciliationDiscrepanciesCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-heading panel-box finanical-check-up" >
                                        <h2 className="panel-title bg-color-panel"> <img alt="" src="/vendors/Tick-mark.png" className="check-icon"></img> Financial Check-Up</h2>
                                        <div className="panel-detail-box">
                                            {/* <div className="row">
                                                <div className="col-12">
                                                    <div className="panel-detail">
                                                        <p> Includes transactions dated 01/01/2019 through today.</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/Financial-Check-up.png"></img>
                                                        <h2 className="panel-sub-title" style={{ color: "#46b29d" }} >Financial</h2>
                                                        <p className="panel-parag">  CHECK-UP</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12" >
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/RNBD', state: { type: LinkType.TranNBD, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.reviewUpdatedTransactionDescriptionsCount}</h4>
                                                                <p> Review Updated Transactions Descriptions</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.reviewUpdatedTransactionDescriptionsNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.reviewUpdatedTransactionDescriptionsNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.reviewUpdatedTransactionDescriptionsNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/tranNBD', state: { type: LinkType.TranNBD, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.needBetterDescriptionCount}</h4>
                                                                <p> {LinkType.TranNBD}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.needBetterDescriptionNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.needBetterDescriptionNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.needBetterDescriptionNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/changeAcNum', state: { type: LinkType.ChangeACNum, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.changeAccountNumberCount}</h4>
                                                                <p>{LinkType.ChangeACNum}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2">
                                                                <img style={{ height: '25px' }} className={financialCheckup.changeAccountNumberNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.changeAccountNumberNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.changeAccountNumberNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/accline', state: { type: LinkType.ACCLineItem, parentState: this.state } }}>

                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.accountsLineItemQueCommentsCount}</h4>
                                                                <p>  {LinkType.ACCLineItem}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.accountsLineItemQueCommentsNewCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.accountsLineItemQueCommentsNewCommentCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.accountsLineItemQueCommentsNewCommentCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/trantagCPA', state: { type: LinkType.TranTagCPA, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.transactionsTaggedAsCPACount}</h4>
                                                                <p> Transactions tagged as “CPA”</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.transactionsTaggedAsCPACommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.transactionsTaggedAsCPACommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.transactionsTaggedAsCPACommentsCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/otherquecomm', state: { type: LinkType.OtherQueComm, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.otherQuestionsAndCommentsCount}</h4>
                                                                <p>{LinkType.OtherQueComm}</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.otherQuestionsAndCommentsCommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.otherQuestionsAndCommentsCommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.otherQuestionsAndCommentsCommentsCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="panel-detail" to={{ pathname: '/generalquetagCPA', state: { type: LinkType.GeneralQueTagCPA, parentState: this.state } }}>
                                                            <div className="panel-detail-content col-md-9">
                                                                <h4>{financialCheckup.otherQuestionsAndCommentsTaggedAsCPACount}</h4>
                                                                <p> Other Questions tagged as "CPA"</p>
                                                            </div>
                                                            <div className="panel-commentCount col-md-2" >
                                                                <img style={{ height: '25px' }} className={financialCheckup.otherQuestionsAndCommentsTaggedAsCPACommentsCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/green_comments.png" alt="comments"></img>
                                                                {financialCheckup.otherQuestionsAndCommentsTaggedAsCPACommentsCount !== 0 && <span className="badge badge-pill badge-danger ng-star-inserted">{financialCheckup.otherQuestionsAndCommentsTaggedAsCPACommentsCount}</span>}
                                                            </div>
                                                            <div className="right-icon col-md-1">
                                                                <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                                <img alt="" className="hover-icon" src="/vendors/Financial-check-up-arrow.png"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-heading panel-box payroll-comliance-box" >
                                        <h2 className="panel-title bg-color-panel"><img alt="" src="/vendors/Tick-mark.png" className="check-icon"></img> 1099 Compliance Issues</h2>
                                        <div className="panel-detail-box">
                                            <div className="row">
                                                {/* <div className="col-md-4" style={{ color: "#46b29d" }}>
                                                    <div className="panel-left-box">
                                                        <img alt="" src="/vendors/Payroll-1099-Compliance-Issues.png"></img>
                                                        <h2 className="panel-sub-title">1099</h2>
                                                        <p className="panel-parag">Compliance Issues</p>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12" >
                                                    <Link className="panel-detail" to={{ pathname: '/vendorMissW9', state: { type: LinkType.VendorMissW9, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-11">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.vendorsMissingW9Count}</h4>
                                                            <p> {LinkType.VendorMissW9}</p>
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/ACCAPP1099', state: { type: LinkType.PendingAccountantApproval1099, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCount}</h4>

                                                            <p> 1099's Pending Accountant's Approval</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount}</span>}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/ACCCLA', state: { type: LinkType.PendingClientApproval1099, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCount}</h4>

                                                            <p>{LinkType.PendingClientApproval1099} </p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingClientApprovalCommentCount}</span>}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/NEC1099', state: { type: LinkType.NEC1099Export, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.vendor1099NECReadyToExportCount}</h4>
                                                            <p> 1099 NEC's Ready to Export</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.vendor1099PendingAccountantApprovalCommentCount}</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/MISC1099', state: { type: LinkType.MISC1099Export, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>
                                                                {this.state.getDashboardData.queriesForVendor1099.vendor1099MISCReadyToExportCount}
                                                            </h4>
                                                            <p>1099 MISC's Ready to Export</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount}</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/Confirm1099', state: { type: LinkType.Confirm1099Mailed, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.confirm1099WereMailedCount}</h4>

                                                            <p>{LinkType.Confirm1099Mailed}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            {/* <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForPayroll1099.vendor1099PendingAccountantApprovalCommentCount}</span>} */}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                    <Link className="panel-detail" to={{ pathname: '/VendorDiscrepancies', state: { type: LinkType.Resolve1099VendorNameDiscrepancies, parentState: this.state } }}>
                                                        <div className="panel-detail-content col-md-9">
                                                            <h4>{this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCount}</h4>

                                                            <p>{LinkType.Resolve1099VendorNameDiscrepancies}</p>
                                                        </div>
                                                        <div className="panel-commentCount col-md-2" >
                                                            <img style={{ height: '25px' }} className={this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount === 0 ? "disableCommentsOpacity" : ""} src="/vendors/orange_comments.png" alt="comments"></img>
                                                            {this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount !== 0 && <span className="badge badge-pill badge-danger  ng-star-inserted">{this.state.getDashboardData.queriesForVendor1099.resolve1099VendorNameDiscrepanciesCommentCount}</span>}
                                                        </div>
                                                        <div className="right-icon col-md-1">
                                                            <img alt="" className="icon-img" src="/vendors/gray-arrow.png"></img>
                                                            <img alt="" className="hover-icon" src="/vendors/Payroll-Arrow.png"></img>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="page-heading panel-box other-issue-box">
                                <h2 className="panel-title bg-color-panel"><img src="/vendors/Tick-mark.png" className="check-icon"></img> Other Issues</h2>
                                <div className="panel-detail-box">
                                    <div className="row">
                                        <div className="col-md-4" style={{ color: "#46b29d" }}>
                                            <div className="panel-left-box">
                                                <img src="/vendors/Other-Issues.png"></img>
                                                <h2 className="panel-sub-title">Other</h2>
                                                <p className="panel-parag">  CHECK-UP</p>
                                            </div>
                                        </div>
                                        <div className="col-md-8" >
                                            <div className="panel-detail">
                                                <div className="panel-detail-content">
                                                    <h4>{this.state.NBDCount}</h4>
                                                    <p> Transaction Need Better Description</p>
                                                </div>
                                                <div className="right-icon">
                                                    <img src="/vendors/gray-arrow.png"></img>
                                                </div>
                                            </div>
                                            <div className="panel-detail">
                                                <div className="panel-detail-content">
                                                    <h4>{this.state.CANCount}</h4>
                                                    <p>Change Account Number</p>
                                                </div>
                                                <div className="right-icon">
                                                    <img src="/vendors/gray-arrow.png"></img>
                                                </div>
                                            </div>
                                            <div className="panel-detail">
                                                <div className="panel-detail-content">
                                                    <h4>{this.state.LIQACount}</h4>
                                                    <p>  Accountant's Line Item Question And Comments</p>
                                                </div>
                                                <div className="right-icon">
                                                    <img src="/vendors/gray-arrow.png"></img>
                                                </div>
                                            </div>
                                            <div className="panel-detail">
                                                <div className="panel-detail-content">
                                                    <h4>{this.state.GQACount}</h4>
                                                    <p>  Accountant's General Question And Comments</p>
                                                </div>
                                                <div className="right-icon">
                                                    <img src="/vendors/gray-arrow.png"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        companyDashboardData: state.companyDashboardData.companyDashboardData,
        company: state.companyReducer.companyDeatils,
        Update_reconciled: state.reconcillition.Update_reconciled,
        Update_depreciation: state.reconcillition.Update_depreciation,
        account_Inactive: state.reconcillition.account_Inactive,
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GET_CompanyDashboard,
        getCompnay,
        Embebded_POST_updatecustomfield,
        UpdateDepreciationDate,
        PUT_QuickbookAccount_UpdateAccountStatus
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard);